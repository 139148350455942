import React from "react";
import { Segment, Dropdown } from "semantic-ui-react";
import WarehouseFilter from "../../Filters/WarehouseFilter";

const optionsDaysSelect = [7, 14, 30].map((nr) => ({
  key: nr,
  value: nr,
  text: `${nr} days`,
}));

function Filters({
  days,
  setDays,
  warehouse,
  setWarehouse,
  product,
  setProduct,
}) {
  return (
    <Segment>
      <div className="flex--between">
        <div className="flex--left">
          <WarehouseFilter
            handleChange={(e, { value }) => setWarehouse(value)}
          ></WarehouseFilter>
        </div>
        <Dropdown
          selectOnBlur={false}
          closeOnChange={true}
          compact
          selection={true}
          name="days"
          value={days}
          onChange={(e, { value }) => setDays(value)}
          options={optionsDaysSelect}
          style={{ marginLeft: "10px" }}
        />
      </div>
    </Segment>
  );
}

export default Filters;
