import {
  apiCall
} from "../services/api";
import {
  addError,
  removeError,
} from "./error";
import {
  startLoading,
  stopLoading
} from "./loading";


const startLoadingTurnOver = {
  type: "START_LOADING"
};
const stopLoadingTurnOver = {
  type: "STOP_LOADING"
};


export const getStockTurnover = (filters) => {

  const url = `${process.env.REACT_APP_API}/reports/stockturnover/?days=${filters.days}&warehouse=${filters.warehouse}`

  return apiCall('get', url)
    .then(resp => (resp))
    .catch((error) => (error));
}


export const updateFilters = (newFilter) => ({
  type: "UPDATE_FILTER",
  payload: newFilter,
});


export const getNogoodsReport = (filters) => (dispatch) => {
  dispatch(startLoadingTurnOver);
  const url = `${process.env.REACT_APP_API}/reports/nogoods/?country=${filters.country}&shipFrom=${filters.shipFrom}`;

  apiCall("get", url)
    .then((resp) => dispatch({
      type: "LOAD_NOGOODS",
      payload: resp
    }))
    .catch((error) => {
      dispatch(stopLoadingTurnOver);
      dispatch(addError(error.message));
    });
};



const createFilterUrl = (filters) =>
  filters ?
  Object.entries(filters)
  .filter(([key, value]) => value)
  .map(([key, value]) => key + "=" + value)
  .join("&") :
  "";

export const getInvoiceCreditNotesSummary = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const filtersUrl = createFilterUrl(filters);
  const url = `${process.env.REACT_APP_API}/reports/creditnotes?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) =>
      dispatch({
        type: "GET_CREDITNOTES_SUMMARY",
        payload: resp
      })
    )
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getInvoices = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const filtersUrl = createFilterUrl(filters);
  const url = `${process.env.REACT_APP_API}/reports/invoices?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => dispatch({
      type: "GET_INVOICES",
      payload: resp
    }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateInvoiceFilters = (newFilter) => ({
  type: "UPDATE_INVOICE_FILTERS",
  payload: newFilter,
});